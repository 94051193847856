import { takeEvery, fork, put, all, call } from "redux-saga/effects"

// Login Redux States
import {GET_PROFILE, UPDATE_PROFILE, UPDATE_USER_PAYMENT,UPDATE_USER_PASSWORD, CLEANUP } from "./actionTypes"
import {profileError, getProfileSuccess,updateProfileSuccess} from "./actions"

//Include Both Helper File with needed methods
import {
  postUserProfile,
  editUserProfile,
  editUserPayment,
  editUserPassword
} from "../../../helpers/fakebackend_helper"
import { updateUser } from "store/contacts/actions"


function* resetState(){

}

function* getUserProfile({ payload: { email,token } }) {
  try {

      const response = yield call(postUserProfile, {
        email
      },{token})
      yield put(getProfileSuccess(response))
      localStorage.setItem('profile', JSON.stringify(response));
      // console.log(response);
  } catch (error) {
    yield put(profileError(error.response.data))
  }
}
function* updateUserProfile({payload:{user,token}}){
  try{
  const response=yield call(editUserProfile,{
   ...user
  },{token});
  yield put(updateProfileSuccess(response));
  }
  catch(error){
    yield put(profileError(error.response.data.error))
  }

}

function* updateUserPayment({payload:{user,token}}){
  // console.log(user);
  // console.log(token);
  try{
  const response=yield call(editUserPayment,{
    ...user
  },{token});
  yield put(updateProfileSuccess(response))
  }
  catch(error){
    yield put(profileError(error.response.data.error))
  }
}
function* updateUserPassword({payload:{user,token}}){
  // console.log(user);
  // console.log(token);
try{

const response=yield call(editUserPassword,{
  ...user
},{token});
yield put(updateProfileSuccess(response))
}
catch(error){
yield put(profileError(error.response.data.error))
}

}
export function* watchProfile() {
  yield takeEvery(GET_PROFILE, getUserProfile)
  yield takeEvery(UPDATE_PROFILE,updateUserProfile)
  yield takeEvery(UPDATE_USER_PAYMENT,updateUserPayment)
  yield takeEvery(UPDATE_USER_PASSWORD,updateUserPassword)
  yield takeEvery(CLEANUP,resetState)
}

function* ProfileSaga() {
  yield all([fork(watchProfile)])
}

export default ProfileSaga
