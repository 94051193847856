import React,{useEffect,useState} from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useSelector } from "react-redux";

import logo from "../../assets/images/logo.png"
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
const ConfirmMail = () => {
  const navigate=useNavigate();
  //meta title
  document.title="Confirm Mail | OnePlay";
  const [msg,setMsg]=useState('');
  const [showCta,setShowCta]=useState(true);
  const [user,setUser]=useState(null);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('t');
    const id = params.get('d');
    setUser(id);
    // console.log(token);
    if (token) {
      axios.get(`${process.env.REACT_APP_API_URL}/verify/${token}`)
        .then(response => {
          // Handle success
          if(response.data.data.success===true){
          setMsg('Your email address has been successfully validated. Our team is currently setting up your account; you will be notified by email when your account is ready!');
          setShowCta(true);
          }
          else{
            setMsg('The verification link has Expired , Please generate a new verification link. Note: The verification link will be valid for 24 hours.');
            setShowCta(false);
          }
        })
        .catch(error => {
          // Handle error
          setMsg('The verification link has Expired , Please generate a new verification link. Note: The verification link will be valid for 24 hours.');
          setShowCta(false);
        });
    }
  }, []);

  const handleRegenerateLink=()=>{
    const response=axios.post(`${process.env.REACT_APP_API_URL}/rvl`,{
      uuid:user
    }).then(response => {
      // console.log(response.data.message);
        toast(response.data.message);
        setTimeout(()=>{
          navigate('/login')
        },5000);
    })
    .catch(()=> {
      toast('Something went wrong. Please try again');
      setShowCta(true);
    });
    
    
    
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
      <ToastContainer />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="d-block text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                </Link>
                <p className="mt-3">OnePlay Partner</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        {showCta?<p className="text-muted">
                        Your email address has been successfully validated. Our team is currently setting up your account; you will be notified by email when your account is ready!
                        </p>:
                        <>
                        <p className="text-muted">
                        The verification link has Expired , Please generate a new verification link.</p>

                         <p className="fw-semibold">Note: The verification link will be valid for 24 hours.</p>
                         </>
                        }

                        {showCta && <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Back to Login
                          </Link>
                        </div>}
                        {!showCta && <div className="mt-4">
                          <button className="btn btn-success" onClick={handleRegenerateLink}>
                          Regenerate verification Link
                          </button>
                        </div>}
                        
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                  </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ConfirmMail
