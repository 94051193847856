import React, { useEffect,useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, FormFeedback,Form} from "reactstrap";
import PropTypes from "prop-types";
// Formik Validation
import * as Yup from "yup";
import { useFormik} from "formik";
import withRouter from "components/Common/withRouter";

// action
import { userResetPassword, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import axios from 'axios';
// import images
import profileImg from "../../assets/images/profile-image.png";
import logoImg from "../../assets/images/logo.png";
import logo from "../../assets/images/logo.png"
const ResetPassword = props => {

  //meta title
  document.title = "Reset Password | OnePlay";

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*+,-./:;<=>?@\^_|~])[A-Za-z\d!#$%&*+,-./:;<=>?@\^_|~]{8,}$/;
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
      confirmPassword:'',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Please Enter Your Password").matches(
        passwordRegex,
        "Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!"
      ),
      confirmPassword: Yup.string().when("password", {
        is: val => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password does not match"
        ),
    }),
  }),
    onSubmit: (values) => {
        // console.log(values);
      dispatch(userResetPassword(values,props.router.navigate));
    }
  });
  const [showScreen,setShowScreen]=useState();
  useEffect(()=>{
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    axios.get(`${process.env.REACT_APP_API_URL}/valid/${token}`).then((response)=>{
      // console.log(response);
      setShowScreen(true);
    }).catch(error=>{
      setShowScreen(false);
    })
  })
  const { resetSuccessMsg,resetError } = useSelector(state => ({
    resetSuccessMsg: state.ResetPassword.resetSuccessMsg,
    resetError: state.ResetPassword.resetError,
  }));

  useEffect(() => {
    dispatch(apiError(""));
  }, []);
  
  const handleShowPassword=()=>{
    setShowPassword((prev)=>!prev);
  }
  const handleShowConfirmPassword=()=>{
    setShowConfirmPassword((prev)=>!prev);
  }
  return (
    <React.Fragment>
      {showScreen ?
      <>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Reset Password</h5>
                        <p>Reset your Password</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profileImg} alt="" className="img-fluid" style={{height: '100px' }} />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logoImg}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {resetError && resetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {resetError}
                      </Alert>
                    ) : null}
                    {resetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {resetSuccessMsg}
                      </Alert>
                    ) : null}


                     

                      
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group">
                          <Input
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.password || ""}
                            invalid={validation.touched.password && validation.errors.password ? true : false}
                          />
                          <button type="button" onClick={handleShowPassword} className="input-group-text"><i className={`bx ${showPassword ? 'bx-hide' : 'bx-show-alt'}`}></i></button>
                          {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                          {!validation.touched.password || !validation.errors.password ? (
    <span className="text-primary" style={{fontSize:'80%'}}>Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!</span>
  ) : null}
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Confirm Password</Label>
                        <div className="input-group">
                          <Input
                            name="confirmPassword"
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Enter Confirm Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.confirmPassword || ""}
                            invalid={validation.touched.confirmPassword && validation.errors.confirmPassword ? true : false}
                          />
                          <button type="button" onClick={handleShowConfirmPassword} className="input-group-text"><i className={`bx ${showConfirmPassword ? 'bx-hide' : 'bx-show-alt'}`}></i></button>
                          {validation.touched.confirmPassword && validation.errors.confirmPassword ? (
                          <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                        ) : null}
                        </div>
                        
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-primary btn-block "
                          type="submit"
                        >
                          Reset Password
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go Back to {" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    {" "}
                    Login
                  </Link>{" "}
                </p>
                <p>
                © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      </>
      :
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="d-block text-center mb-5 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="20"
                    className="auth-logo-dark mx-auto"
                  />
                </Link>
                <p className="mt-3">OnePlay Partner</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <p className="text-muted">
                        Your link has expired.
                        </p>
                        <div className="mt-4">
                          <Link to="/login" className="btn btn-success">
                            Back to Login
                          </Link>
                        </div>
                        
                        
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                  </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
}
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);

ResetPassword.propTypes = {
  history: PropTypes.object,
};