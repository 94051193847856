import PropTypes from "prop-types";
import React,{useState,useEffect} from "react";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import {getStatus,getUserProfile, loginUser, socialLogin } from "../../store/actions";

// import images
import profile from "assets/images/profile-image.png";
import logo from "../../assets/images/logo.png";

const Login = props => {

  //meta title
  document.title = "Login | OnePlay";
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&*+,-./:;<=>?@\^_|~])[A-Za-z\d!#$%&*+,-./:;<=>?@\^_|~]{8,}$/;
  const emailRegex= /^[a-zA-Z0-9-_.]+@[a-zA-Z0-9-_]+\.[a-zA-Z]{2,}$/;
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: '',
      currentPassword:'',
    },
    validationSchema: Yup.object({
      email: Yup.string()
      .email("Must be a valid Email")
      .max(255).required("Please Enter Your Email").matches(
        emailRegex,
        "Not a valid email address"
      ),
      currentPassword: Yup.string().required("Please Enter Your Password").matches(
        passwordRegex,
        "Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!"
      ),
    }),
    onSubmit: (values) => {
      // console.log(values);
      dispatch(loginUser(values, props.router.navigate));
    }
  });

 

  const { user, error,success_msg,error_msg } = useSelector(state => ({
    user:state.Login.user,
    error: state.Login.error,
    success_msg:state.Login.success_msg,
    error_msg:state.Login.error_msg
  }));

  useEffect(()=>{
  if(user){
    // console.log(user);
  dispatch(getStatus(user.token))
  }
  },[success_msg])

    const signIn = type => {
        dispatch(socialLogin(type, props.router.navigate));
    };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };


  const handleShowPassword=()=>{
    setShowPassword((prev)=>!prev);
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back!</h5>
                        <p>Sign in to continue to OnePlay.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" style={{height: '100px'}}/>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                  <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                       {/* {success_msg && user && user ? (
                        <Alert color="success">
                          Login Successful
                        </Alert>
                      ) : null}  */}
                      {error_msg && error ? (<Alert color="danger">{error}</Alert>) : null}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <div className="input-group">
                          <Input
                            name="currentPassword"
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.currentPassword || ""}
                            invalid={validation.touched.currentPassword && validation.errors.currentPassword ? true : false}
                          />
                          <button type="button" onClick={handleShowPassword} className="input-group-text"><i className={`bx ${showPassword ? 'bx-hide' : 'bx-show-alt'}`}></i></button>
                          {validation.touched.currentPassword && validation.errors.currentPassword ? (
                          <FormFeedback type="invalid">{validation.errors.currentPassword}</FormFeedback>
                        ) : null}
                         {!validation.touched.currentPassword || !validation.errors.currentPassword ? (
    <span className="text-primary" style={{fontSize:'80%'}}>Password should be at least 8 characters, 1 UPPERCASE, 1 lowercase, 1 Symbol and 1 number!</span>
  ) : null}
                        </div>
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Don&#39;t have an account ?{" "}
                  <Link to="/register" className="fw-medium text-primary">
                    {" "}
                    Signup now{" "}
                  </Link>{" "}
                </p>
                <p>
                © {new Date().getFullYear()} RainBox Media Pvt Ltd. All Rights Reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
